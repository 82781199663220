import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { Edit, Loader2, Trash2 } from "lucide-react";
import React, { useEffect, useState, useCallback } from "react";
import { auth, db } from "../../../firebase.js";
import toast from "react-hot-toast";
// import { EditUserDialog } from "./EditUserDialog.jsx";

function Messages({ done }) {
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUsers = useCallback(async () => {
    try {
      const ref = collection(db, "nhp");
      const snapshot = await getDocs(ref);
      const queries = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(queries);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, isOpen, done]);

  const handleDelete = (id) => {
    setOpen(true);
    setSelectedItemId(id);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      const ref = doc(db, "nhp", selectedItemId);
      await deleteDoc(ref);
      toast.success("Message deleted successfully!");

      await fetchUsers(); // Refresh the user list
      setOpen(false);
    } catch (error) {
      console.error("Error deleting user!", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getPermissionDisplay = (permissions) => {
    if (!permissions || permissions.length === 0) return "No permissions";
    return permissions
      .map((p) => {
        const [category, action] = p.split("-");
        return `${category} (${action})`;
      })
      .join(", ");
  };

  return (
    <div className="min-h-screen">
      <div className="bg-white p-6 rounded-lg shadow-md overflow-x-auto">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Users</h2>
        {data.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Subject
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Message
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((user) => (
                <tr
                  key={user.id}
                  className="hover:bg-gray-50 transition duration-150 ease-in-out"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {user.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {user.phone_number}
                    </div>
                  </td>{" "}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{user.subject}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{user.message}</div>
                    {user.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleDelete(user.id)}
                      className="p-2 rounded-full hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-150 ease-in-out"
                    >
                      <Trash2 className="w-5 h-5 text-red-500" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-500 text-center py-4">No messages found.</p>
        )}
      </div>
      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-4">
              Are you sure you want to delete this message?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setOpen(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150 ease-in-out"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                    Deleting...
                  </div>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <EditUserDialog isOpen={isOpen} user={user} setIsOpen={setIsOpen} /> */}
    </div>
  );
}

export default Messages;
