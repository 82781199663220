import React, { useEffect, useState } from "react";
import { WaterLevelTable } from "./WaterLevelTable";
import WaterLevelInput from "./WaterLevelInput";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import toast from "react-hot-toast";
const Records = () => {
  const [trigger, setTrigger] = useState(false);

  return (
    <>
      <div>
        <h1 className="text-3xl mb-5">Water Level Records</h1>
        <WaterLevelTable trigger={trigger} />
      </div>{" "}
      <div className="mt-5">
        <h1 className="text-3xl mb-5">Water Level Input</h1>
        <WaterLevelInput trigger={trigger} setTrigger={setTrigger} />
      </div>
    </>
  );
};

export default Records;
