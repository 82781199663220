import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import Make from "./components/Make";
import ProtectedRouteForPart from "./components/Route/SpecialUsers/ProtectedRouteForPart";
import Header2 from "./components/nhp/HeaderFooter/Header";
import Header from "./components/HeaderFooter/Header";
import Footer2 from "./components/HeaderFooter/FooterCom";
import OnlyAdminPrivateRoute from "./components/Route/OnlyAdminPrivateRoute";
import OnlyAdminPrivateRoute2 from "./components/nhp/admin/OnlyAdminPrivateRoute";
import PrivateRoute from "./components/Route/PrivateRoute";
import ProtectedRouteForSpecial from "./components/Route/SpecialUsers/ProtectedRouteForSpecial";
import Loading from "./components/Misc/Loading";
import CEC from "./pages/admin/Demo/CEC";
import CECs from "./pages/admin/Demo/CECs";
import Certificate from "./pages/admin/Demo/Certificate";
import Upload from "./pages/admin/Restricted/Upload";
import MarketPlace from "./pages/marketplace/MarketPlace";
import ProductsPage from "./components/marketPlace/ProductsPage";
import CheckoutPage from "./pages/marketplace/CheckoutPage";
import QRTarget from "./pages/admin/Demo/QRTarget";
import ScrollToTop from "./components/Misc/ScrollToTop";
import FooterCom from "./components/nhp/headerfooter/FooterCom";
import AdminDashboard from "./pages/nhp/admin/AdminDashboard";
import MapCollection from "./pages/nhp/maps/MapCollection";
import SikkimMap from "./pages/nhp/maps/SikkimMap";
import SignIn from "./pages/nhp/admin/SignIn";

const PrivacyPolicy = React.lazy(() => import("./pages/Misc/PrivacyPolicy"));
const ScheduleForm = React.lazy(
  () => import("./components/forms/Lessons/ScheduleForm")
);
const Graphs = React.lazy(() => import("./pages/admin/Restricted/Graphs"));

const ArtForm = React.lazy(() => import("./components/forms/Lessons/ArtForm"));

const ItSolutions = React.lazy(
  () => import("./components/forms/professional/ItSolutions")
);
const IT = React.lazy(() => import("./pages/admin/Managements/IT"));
const InfluencersForm = React.lazy(
  () => import("./components/forms/influencers/InfluencersForm")
);
const InfluencersListing = React.lazy(
  () => import("./pages/listings/InfluencersListing")
);
const Home2 = React.lazy(() => import("./pages/Home"));
const Home = React.lazy(() => import("./pages/nhp//home/Home"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const MainReview = React.lazy(() => import("./pages/listings/MainReview"));
const NotFound = React.lazy(() => import("./components/Route/NotFound"));
const ToursForm = React.lazy(() => import("./components/forms/ToursForm"));
const GetListed = React.lazy(() => import("./pages/users/GetListed"));
const ListOfServices = React.lazy(
  () => import("./pages/listings/ListsOfServices")
);
const ClinicForm = React.lazy(() => import("./components/forms/ClinicForm"));
const HealthCare = React.lazy(() => import("./pages/users/HealthCare"));
const Health = React.lazy(() => import("./pages/admin/Health"));
const DentalClinicForm = React.lazy(
  () => import("./components/forms/DentalClinicForm")
);
const ListOfServicesIndustry = React.lazy(
  () => import("./pages/listings/ListOfServicesIndustry")
);
const MainReviewIndustry = React.lazy(
  () => import("./pages/listings/MainReviewIndustry")
);
const CAForm = React.lazy(
  () => import("./components/forms/professional/CAForm")
);
const Page = React.lazy(() => import("./pages/admin/Restricted/Page"));
const Details = React.lazy(() => import("./pages/admin/Restricted/Details"));
const Constituency = React.lazy(
  () => import("./pages/admin/Restricted/Constituency")
);
const RestaurantForm = React.lazy(
  () => import("./components/forms/RestaurantForm")
);
const AdminDashboard2 = React.lazy(
  () => import("./pages/admin/AdminDashboard")
);

// Initialize Google Analytics
const TRACKING_ID = "G-KJ91VJL30M";
ReactGA.initialize(TRACKING_ID);

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <Toaster />
      <ScrollToTop />{" "}
      <Routes>
        <Route
          path="/nhp/*"
          element={
            <>
              <Header2 />{" "}
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/" element={<Home />} />{" "}
                  <Route path="/admin-login" element={<SignIn />} />
                  <Route element={<OnlyAdminPrivateRoute2 />}>
                    <Route path="/dashboard" element={<AdminDashboard />} />
                  </Route>
                  <Route
                    path="/water-resources/:mapType"
                    element={<SikkimMap />}
                  />{" "}
                  <Route path="/water-resources" element={<MapCollection />} />{" "}
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </Suspense>
              <FooterCom />
            </>
          }
        />

        <Route
          path="/*"
          element={
            <>
              <Header />
              <ScrollToTop />
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/" element={<Home2 />} />
                  <Route path="/tours" element={<ToursForm />} />
                  <Route path="/market" element={<MarketPlace />} />
                  <Route path="/market/cart" element={<CheckoutPage />} />
                  <Route path="/market/:productId" element={<ProductsPage />} />
                  <Route path="/influencers" element={<InfluencersListing />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/sikkim-food/:resName"
                    element={<MainReview reviewFor="restaurants" />}
                  />
                  {/* <Route path="/upload" element={<Upload />} /> */}
                  <Route
                    path="/tours-travels/:resName"
                    element={<MainReview reviewFor="tours" />}
                  />
                  <Route
                    path="/health-care/:resName"
                    element={<MainReview reviewFor="health" />}
                  />{" "}
                  <Route path="/get-listed" element={<GetListed />} />
                  <Route
                    path="/chartered-accountant/:resName"
                    element={
                      <MainReviewIndustry
                        category="finance"
                        industry="professional-services"
                        businessType="chartered-accountant"
                      />
                    }
                  />
                  <Route
                    path="/dental-clinic/:resName"
                    element={
                      <MainReviewIndustry
                        category="clinics"
                        industry="healthcare"
                        businessType="dentist"
                      />
                    }
                  />
                  <Route
                    path="/recreational-activities/:resName"
                    element={
                      <MainReviewIndustry
                        category="art"
                        industry="education"
                        businessType="art"
                      />
                    }
                  />
                  <Route
                    path="/it/:resName"
                    element={
                      <MainReviewIndustry
                        category="it"
                        industry="professional-services"
                        businessType="it"
                      />
                    }
                  />
                  <Route
                    path="/sikkim-food"
                    element={
                      <ListOfServices
                        serviceType="restaurants"
                        link="sikkim-food"
                      />
                    }
                  />{" "}
                  <Route
                    path="/dental-clinic"
                    element={
                      <ListOfServicesIndustry
                        businessType="dentist"
                        industry="healthcare"
                        category="clinics"
                        link="dental-clinic"
                      />
                    }
                  />
                  <Route
                    path="/tours-travels"
                    element={
                      <ListOfServices
                        serviceType="tours"
                        link="tours-travels"
                      />
                    }
                  />
                  <Route
                    path="/chartered-accountant"
                    element={
                      <ListOfServicesIndustry
                        businessType="chartered-accountant"
                        industry="professional-services"
                        category="finance"
                        link="chartered-accountant"
                      />
                    }
                  />
                  <Route
                    path="/it"
                    element={
                      <ListOfServicesIndustry
                        businessType="it"
                        industry="professional-services"
                        category="it"
                        link="it"
                      />
                    }
                  />{" "}
                  <Route
                    path="/recreational-activities"
                    element={
                      <ListOfServicesIndustry
                        businessType="art"
                        industry="education"
                        category="art"
                        link="recreational-activities"
                      />
                    }
                  />
                  <Route
                    path="/health-care"
                    element={
                      <ListOfServices serviceType="health" link="health-care" />
                    }
                  />
                  <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/get-listed/restaurants"
                      element={<RestaurantForm />}
                    />
                    <Route path="/get-listed/tours" element={<ToursForm />} />
                    <Route path="/get-listed/sch" element={<ScheduleForm />} />
                    <Route path="/get-listed/art" element={<ArtForm />} />
                    <Route
                      path="/get-listed/influencers"
                      element={<InfluencersForm />}
                    />
                    <Route
                      path="/get-listed/dental-clinic"
                      element={<DentalClinicForm />}
                    />
                    <Route
                      path="/get-listed/professionals"
                      element={<CAForm />}
                    />
                    <Route
                      path="/get-listed/health-care"
                      element={<HealthCare />}
                    />
                    <Route path="/get-listed/it" element={<ItSolutions />} />
                    <Route
                      path="/get-listed/health-care/clinic"
                      element={<ClinicForm />}
                    />
                  </Route>
                  <Route element={<OnlyAdminPrivateRoute />}>
                    <Route path="/admin-skm" element={<AdminDashboard2 />} />
                    <Route path="/graph" element={<Graphs />} />
                    <Route
                      path="/constituency/:cons/:part/:page/:id"
                      element={<Details />}
                    />
                    <Route
                      path="/constituency/:cons/:part/:page"
                      element={<Page />}
                    />
                    {/* <Route path="/constituency/:cons/:part" element={<PageNo />} /> */}
                    <Route path="/constituency" element={<Constituency />} />
                    <Route
                      element={<RestaurantForm />}
                      path="/dashboard/restaurants/update/:resName"
                    />{" "}
                    <Route
                      element={<ArtForm />}
                      path="/dashboard/art/update/:resName"
                    />
                    <Route
                      element={<ToursForm />}
                      path="/dashboard/tours/update/:resName"
                    />
                    <Route path="/cec" element={<CECs />} />
                    <Route path="/cec/:detailId" element={<QRTarget />} />
                    <Route path="/cec/:id" element={<Certificate />} />
                    <Route
                      element={<CAForm />}
                      path="/dashboard/chartered-accountant/update/:resName"
                    />
                    <Route
                      element={<ItSolutions />}
                      path="/dashboard/it/update/:resName"
                    />
                    <Route
                      element={<InfluencersForm />}
                      path="/dashboard/influencers/update/:resName"
                    />{" "}
                    {/* <Route path="/salghari/graphs" element={<Graphs />} /> */}
                    <Route
                      element={<ClinicForm />}
                      path="/dashboard/health/update/:resName"
                    />
                    {/* <Route path="/make" element={<Make />} /> */}
                    <Route
                      element={<DentalClinicForm />}
                      path="/dashboard/dentist/update/:resName"
                    />
                    <Route element={<Health />} path="/dashboard/health" />
                  </Route>
                  <Route element={<ProtectedRouteForSpecial />}>
                    <Route path="/constituency" element={<Constituency />} />
                  </Route>
                  <Route element={<ProtectedRouteForPart />}>
                    <Route
                      path="/constituency/:cons/:part/:page/:id"
                      element={<Details />}
                    />
                    <Route
                      path="/constituency/:cons/:part/:page"
                      element={<Page />}
                    />
                    {/* <Route path="/constituency/:cons/:part" element={<PageNo />} /> */}
                  </Route>
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </Suspense>
              <Footer2 />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
