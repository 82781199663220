import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { signoutSuccess } from "../../../redux/user/userSlice";

function OnlyAdminPrivateRoute() {
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const sessionChecker = async () => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        dispatch(signoutSuccess());
        setIsSessionExpired(true);
      }
    });
  };

  useEffect(() => {
    sessionChecker();
  }, [currentUser, dispatch, auth]);

  if (isSessionExpired) {
    return <Navigate to="/nhp" />;
  }

  // Only show the outlet if the user is an admin
  return currentUser && currentUser.isAdmin ? (
    <div>
      <Outlet />
    </div>
  ) : (
    <Navigate to="/not-found" />
  );
}

export default OnlyAdminPrivateRoute;
