import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { db } from "../../../firebase";

export default function RiverReadingsForm({ setTrigger, trigger }) {
  const [formData, setFormData] = useState([
    {
      id: "1",
      riverCatchment: "Teesta",
      riverName: "Rateychu",
      stationName: "Rateychu (B1) Bridge",
      readings: [{}],
    },
    {
      id: "2",
      riverCatchment: "Teesta",
      riverName: "Roro-chu",
      stationName: "Lagyep Bridge,Ranipool",
      readings: [{}],
    },
    {
      id: "3",
      riverCatchment: "Teesta",
      riverName: "Rongni-chu",
      stationName: "32 no, Bridge",
      readings: [{}],
    },
    {
      id: "4",
      riverCatchment: "Teesta",
      riverName: "Teesta",
      stationName: "Mamring Bridge",
      readings: [{}],
    },
    {
      id: "5",
      riverCatchment: "Teesta",
      riverName: "Dikchu khola",
      stationName: "Dikchu Khola",
      readings: [{}],
    },
    {
      id: "6",
      riverCatchment: "Teesta",
      riverName: "Rangpo-chu",
      stationName: "Lossing Bridge",
      readings: [{}],
    },
    {
      id: "7",
      riverCatchment: "Teesta",
      riverName: "Rongli-chu",
      stationName: "Rongli Bridge",
      readings: [{}],
    },
    {
      id: "8",
      riverCatchment: "Teesta",
      riverName: "Rangpo-chu",
      stationName: "Rorathang Bridge",
      readings: [{}],
    },
    {
      id: "9",
      riverCatchment: "Teesta",
      riverName: "Rangeet",
      stationName: "Akkar Bridge",
      readings: [{}],
    },
    {
      id: "10",
      riverCatchment: "Teesta",
      riverName: "Rangeet",
      stationName: "Legship Bridge",
      readings: [{}],
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const ref = doc(db, "nhp", "waterLevels");

      // Fetch the existing document
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const existingData = docSnap.data().data || [];

        // Merge the new data with the existing data
        const updatedData = formData.map((station) => {
          const existingStation = existingData.find((s) => s.id === station.id);
          if (existingStation) {
            // Merge readings, keeping all unique dates
            const mergedReadings = [
              ...station.readings,
              ...existingStation.readings.filter(
                (r) => !station.readings.some((sr) => sr.date === r.date)
              ),
            ];
            return { ...station, readings: mergedReadings };
          }
          return station; // New station
        });

        // Update Firestore with merged data
        await updateDoc(ref, { data: updatedData });
        toast.success("Data updated successfully!");
        setTrigger(!trigger);
      } else {
        // Document doesn't exist, create it
        await updateDoc(ref, { data: formData });
        toast.success("New document created successfully!");
      }
    } catch (error) {
      console.error("Error updating Firestore:", error);
      toast.error("Failed to update Firestore.");
    }
  };
  const getCurrentDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    // return `12-11-2024`;
    return `${day}-${month}-${year}`;
  };
  const handleInputChange = (stationId, field, value) => {
    const ALLOWED_FIELDS = ["time", "level"];
    if (!ALLOWED_FIELDS.includes(field)) return; // Ignore invalid fields

    setFormData((prevData) =>
      prevData.map((station) => {
        if (station.id === stationId) {
          const currentDate = getCurrentDate();

          // Clone the existing readings to avoid mutating state directly
          const updatedReadings = [...station.readings];

          // Check if today's reading already exists
          const todayIndex = updatedReadings.findIndex(
            (reading) => reading.date === currentDate
          );

          if (todayIndex !== -1) {
            // Update the specific field in the existing entry
            updatedReadings[todayIndex] = {
              ...updatedReadings[todayIndex],
              [field]: value,
            };
          } else {
            // Add a new reading for today
            const newReading = {
              date: currentDate,
              time: field === "time" ? value : "",
              level: field === "level" ? value : "",
            };
            updatedReadings.unshift(newReading); // Add to the beginning of the array
          }

          return {
            ...station,
            readings: updatedReadings,
          };
        }
        return station;
      })
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5">
      <div className="flex flex-wrap justify-center items-center gap-5">
        {formData.map((station) => (
          <div
            key={station.id}
            className="border p-4 rounded-lg flex flex-col justify-center items-center bg-white"
          >
            <h3 className="font-bold mb-2">{station.stationName}</h3>
            <p>River: {station.riverName}</p>
            <p>Catchment: {station.riverCatchment}</p>
            <div className="mt-2">
              <label className="block">
                Date:
                <input
                  type="text"
                  value={getCurrentDate()}
                  readOnly
                  className="border px-2 py-1 ml-2"
                />
              </label>
            </div>
            <div className="mt-2">
              <label className="block">
                Time:
                <input
                  type="time"
                  value={station.readings[0]?.time || ""}
                  onChange={(e) =>
                    handleInputChange(station.id, "time", e.target.value)
                  }
                  className="border px-2 py-1 ml-2"
                />
              </label>
            </div>
            <div className="mt-2">
              <label className="block">
                Water Level (m):
                <input
                  type="number"
                  step="0.01"
                  value={station.readings[0]?.level || ""}
                  onChange={(e) =>
                    handleInputChange(station.id, "level", e.target.value)
                  }
                  className="border px-2 py-1 ml-2"
                />
              </label>
            </div>
          </div>
        ))}
      </div>
      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Submit All Readings
      </button>
    </form>
  );
}
