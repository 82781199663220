import React, { useState } from "react";
import { Footer, Modal } from "flowbite-react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { FaShareAlt } from "react-icons/fa";
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsLinkedin,
  BsGithub,
} from "react-icons/bs";
import ReactGA from "react-ga4";
function FooterCom() {
  const [open, setOpen] = useState(false);
  const shareUrl = window.location.href;

  const handleTracking = (platform) => {
    ReactGA.event({
      action: "share",
      category: "sikkim.co",
      label: `User is sharing sikkim.co to ${platform}`,
    });
  };

  return (
    <>
      <Footer
        container
        className="border  border-t-8 border-blue-400 bg-gradient-to-br  dark:from-gray-100 dark:to-black"
      >
        <div className="w-full  max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row items-center justify-center py-4 space-y-4 sm:space-y-0">
            <Footer.Copyright
              href="/nhp"
              by="NHP Sikkim"
              year={new Date().getFullYear()}
              className="dark:text-white"
              aria-label="NE developers"
            />
            {/* <p className="italic text-sm text-slate-600">
              A product of NE Developers
            </p>
            <div className="flex items-center space-x-6">
              <Footer.Icon
                href="https://www.facebook.com/people/Sikkimco/61564627247187/#"
                target="_blank"
                icon={BsFacebook}
                className="dark:text-white"
                aria-label="Facebook Link"
              />
              <Footer.Icon
                href="https://www.instagram.com/sikkim.co/"
                icon={BsInstagram}
                className="dark:text-white"
                target="_blank"
                aria-label="Instagram Link"
              />
              <FaShareAlt
                onClick={() => setOpen(true)}
                className="cursor-pointer text-lg text-gray-500 hover:text-gray-700 dark:text-white transition-colors"
              />
            </div> */}
          </div>
        </div>
      </Footer>
      {/* <Modal
        show={open}
        onClose={() => setOpen(false)}
        className={`flex items-center justify-center`}
      >
        <Modal.Header onClose={() => setOpen(false)}>
          Share us on your socials
        </Modal.Header>
        <Modal.Body className="flex gap-5 justify-center">
          <FacebookShareButton
            url={shareUrl}
            quote="Visit Now"
            onClick={() => handleTracking("Facebook")}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title="Visit Now"
            onClick={() => handleTracking("Whatsapp")}
          >
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>

          <TwitterShareButton
            url={shareUrl}
            title="Visit Now"
            onClick={() => handleTracking("Twitter")}
          >
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>

          <TelegramShareButton
            url={shareUrl}
            title="Visit Now"
            onClick={() => handleTracking("Telegram")}
          >
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default FooterCom;
