import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Menu, X } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../../../assets/nhp/default/logo.png";
const navItems = [
  {
    name: "Home",
    href: "/nhp",
    // hasDropdown: true,
    // dropdownItems: ["Advisors", "Chairpersons", "Ministers", "MLAs"],
    // dropdownLinks: [
    //   "/members/advisors",
    //   "/members/chairpersons",
    //   "/members/ministers",
    //   "/members/mlas",
    // ],
  },
  {
    name: "Dashboard",
    href: "/nhp/dashboard",
    hasDropdown: false,
    dropdownItems: [
      "About P.S Golay",
      "Party Vision",
      "Dozen Doctrine",
      "Administration Vision",
      "Calendar/Events",
    ],
    dropdownLinks: [
      "/about/p-s-golay",

      "/about/vision",
      "/about/dozen-doctrine",
      "/about/administrative-vision",
      "/about/events",
    ],
  },
  {
    name: "Water Resources",
    href: "/nhp/water-resources",
    hasDropdown: false,
    dropdownItems: ["Chairpersons", "Frontal Organizations", "MLAs"],
    dropdownLinks: [
      // "/members/advisors",
      "/members/chairpersons",
      "/frontal-organization",
      // "/members/ministers",
      "/members/mlas",
    ],
  },

  // {
  //   name: "MEDIA",
  //   href: "/media",
  //   hasDropdown: true,
  //   dropdownItems: ["Submenu Item 1", "Submenu Item 2", "Submenu Item 3"],
  //   dropdownLinks: ["/media/submenu1", "/media/submenu2", "/media/submenu3"],
  // },
  {
    // name: "Contact Us",
    href: "/contact-us",
    hasDropdown: false,
    dropdownItems: ["Feedbacks"],
    dropdownLinks: ["/feedback/"],
  },
];
export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (name) => {
    setActiveDropdown(activeDropdown === name ? null : name);
  };

  const handleDropdownItemClick = (event) => {
    event.stopPropagation();
    setActiveDropdown(null);
  };

  const renderNavItems = (mobile = false) => (
    <>
      {navItems.map((item, index) => (
        <div key={index} className={`relative ${mobile ? "mb-6" : ""}`}>
          {item.hasDropdown ? (
            <div className="flex items-center gap-2">
              <Link
                to={item.href || "#"}
                onClick={() => setMobileMenuOpen(false)}
                className="flex items-center space-x-1 text-base font-medium text-black hover:text-orange-500 transition-colors duration-200"
              >
                <span>{item.name}</span>
              </Link>
              <ChevronDown
                onClick={() => toggleDropdown(item.name)}
                className="h-4 w-4 mt-0.5 text-black hover:text-orange-500 transition-colors duration-200 cursor-pointer"
              />
            </div>
          ) : (
            <Link
              to={item.href}
              className="text-base font-medium text-black hover:text-orange-500 transition-colors duration-200"
              onClick={() => setMobileMenuOpen(false)} // Close mobile menu after navigation
            >
              {item.name}
            </Link>
          )}
          {item.hasDropdown && activeDropdown === item.name && (
            <div
              ref={dropdownRef}
              className={`${
                mobile ? "mt-2" : "absolute top-full left-0 mt-2"
              } w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50`}
            >
              <div
                className="py-2"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {item.dropdownItems.map((subItem, index) => (
                  <Link
                    key={index}
                    to={item.dropdownLinks[index]}
                    onClick={(event) => {
                      handleDropdownItemClick(event);
                      setMobileMenuOpen(false); // Ensure mobile menu closes
                    }}
                    className="block px-4 py-3 text-sm text-gray-700 hover:bg-orange-100 hover:text-orange-900 transition-colors duration-200"
                    role="menuitem"
                  >
                    {subItem}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );

  return (
    <header className="bg-white shadow z-50 border-b-2 border-blue-500">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between">
          <div className="flex items-center justify-between w-full lg:w-fit lg:justify-normal">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="lg:hidden mr-4 text-black hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <Menu className="h-6 w-6" />
              <span className="sr-only">Open menu</span>
            </button>
            <Link to="/nhp" className="flex-shrink-0">
              {" "}
              <img src={logo} className="w-16 h-16" />
              {/* <img width={64} height={64} src="/favicon.png" alt="Logo" /> */}
            </Link>
          </div>
          <div className="hidden lg:flex lg:items-center lg:space-x-8">
            {renderNavItems()}
          </div>
          {/* <div className="flex items-center">
            <Link
              to="/connect"
              className="bg-green-500 py-2 px-6 rounded-md text-base font-medium text-white hover:bg-green-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Connect Digitally
            </Link>
          </div> */}
        </div>
      </nav>

      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="lg:hidden fixed inset-0 z-50 overflow-hidden"
            aria-modal="true"
          >
            <div
              className="absolute inset-0 bg-gray-900 bg-opacity-50"
              aria-hidden="true"
            />
            <motion.div className="fixed inset-y-0 left-0 max-w-xs w-full bg-white shadow-xl overflow-y-auto">
              <div className="flex items-center justify-between p-4 border-b border-blue-600">
                <Link
                  to="/nhp"
                  className="flex-shrink-0"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <img src={logo} className="w-16 h-16" />
                  {/* <img width={48} height={48} src="/favicon.png" alt="Logo" /> */}
                </Link>
                <button
                  onClick={() => setMobileMenuOpen(false)}
                  className="text-black hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <X className="h-6 w-6" />
                  <span className="sr-only">Close menu</span>
                </button>
              </div>
              <div className="px-4 py-6 space-y-6">{renderNavItems(true)}</div>
              {/* <div className="mt-auto p-4 border-t border-blue-600">
                <Link
                  to="/connect"
                  onClick={() => setMobileMenuOpen(false)}
                  className="w-full bg-green-500 py-2 px-4 rounded-md text-base font-medium text-white hover:bg-green-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Connect Digitally
                </Link>
              </div> */}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
