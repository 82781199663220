import React, { useEffect, useState } from "react";
import {
  Menu,
  Users,
  UserCheck,
  Briefcase,
  Building,
  ChevronDown,
  ChevronsLeftRightEllipsis,
  MessageCircle,
  ChevronUp,
  UserRoundPlus,
  GitPullRequestArrow,
  Pencil,
  MessageCircleWarning,
  CalendarDays,
  X,
  BookUser,
  List,
} from "lucide-react";

import Messages from "../../../components/nhp/admin/Messages";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { signoutSuccess } from "../../../redux/user/userSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import WaterLevel from "../../../components/nhp/admin/WaterLevel";

export default function AdminDashboard() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputsExpanded, setInputsExpanded] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Set initial selected item based on URL
    const initialItem = searchParams.get("tab");
    if (initialItem) {
      setSelectedItem(initialItem);
    }
  }, []);

  const menuItems2 = [
    { name: "Messages", icon: MessageCircle },
    { name: "Water Level Records", icon: List },
  ];
  const renderComponent = () => {
    const componentParam = searchParams.get("tab");
    if (componentParam === "messages") {
      return <Messages />;
    } else if (componentParam === "water-level-records") {
      return <WaterLevel />;
    }
    switch (selectedItem) {
      case "Messages":
        return <Messages />;
      case "Records":
        return <WaterLevel />;

      default:
        return <div>Select an item from the menu</div>;
    }
  };

  const dispatch = useDispatch();
  const handleSignOut = async () => {
    try {
      signOut(auth);
      dispatch(signoutSuccess());
      navigate("/nhp/admin-login");
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuItemClick = (itemName) => {
    if (itemName === "Messages") {
      setSearchParams({ tab: "messages" });
    } else if (itemName === "Water Level Records") {
      setSearchParams({ tab: "water-level-records" });
    }
    // Close menu on mobile after selection
    if (window.innerWidth < 768) {
      setMenuOpen(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100 relative">
      {/* Overlay for mobile */}
      {menuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden"
          onClick={() => setMenuOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed md:static inset-y-0 left-0 z-30 bg-black text-white transition-all duration-300 ease-in-out ${
          menuOpen ? "w-64" : "w-0 md:w-20"
        } overflow-hidden`}
      >
        <div className="flex justify-between items-center p-4">
          <div
            onClick={() => setMenuOpen(!menuOpen)}
            className="cursor-pointer hover:bg-gray-700 p-2 rounded"
          >
            <Menu className="w-6 h-6" />
          </div>
          <button
            onClick={() => setMenuOpen(false)}
            className="md:hidden hover:bg-gray-700 p-2 rounded"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <nav className="space-y-1">
          {/* {inputsExpanded &&
            menuItems.map((item) => (
              <div
                key={item.name}
                onClick={() => handleMenuItemClick(item.name)}
                className={`flex items-center ${
                  menuOpen ? "pl-8" : "justify-center"
                } p-4 cursor-pointer hover:bg-gray-700 ${
                  selectedItem === item.name ? "bg-gray-700" : ""
                }`}
              >
                <item.icon className="w-6 h-6 mr-2" />
                {menuOpen && <span>{item.name}</span>}
              </div>
            ))} */}

          {menuItems2.map((item) => (
            <div
              key={item.name}
              onClick={() => handleMenuItemClick(item.name)}
              className={`flex items-center ${
                menuOpen ? "" : "justify-center"
              } p-4 cursor-pointer hover:bg-gray-700 ${
                selectedItem === item.name ? "bg-gray-700" : ""
              }`}
            >
              <item.icon className="w-6 h-6 mr-2" />
              {menuOpen && <span>{item.name}</span>}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4 md:p-10 overflow-hidden">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-5 space-y-4 md:space-y-0">
          <h1 className="text-xl md:text-3xl font-bold break-words max-w-full">
            Welcome Admin
          </h1>
          <div className="flex items-center space-x-4 w-full md:w-auto">
            <button
              onClick={() => setMenuOpen(true)}
              className="md:hidden px-4 py-2 text-sm bg-black text-white rounded hover:bg-gray-700"
            >
              Menu
            </button>
            <button
              onClick={handleSignOut}
              className="px-4 py-2 text-sm bg-black text-white rounded hover:bg-gray-500 w-full md:w-auto"
            >
              Sign Out
            </button>
          </div>
        </div>
        <div className="mt-4">{renderComponent()}</div>
      </div>
    </div>
  );
}
