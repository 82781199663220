import React from "react";
import { Link } from "react-router-dom";

const MapCollection = () => {
  const links = [
    {
      name: "Sikkim Wetland Map 2017-2018",
      imageUrl:
        "https://www.nesdr.gov.in/geoportal/cgi-bin/IGiS_Ent_service.exe?IEG_PROJECT=SK_Wetland_WS&LAYERS=SK_Wetland_17_18&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.230360473633,88.908,27.910139526367&WIDTH=314&HEIGHT=247",
    },
    {
      name: "Sikkim Large Scale Drainage Network",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/Sikkim_Water_ResourceWS/wms?LAYERS=SK_Drainage&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.356291260813,88.908, 28.040190186595&WIDTH=163&HEIGHT=382",
    },
    {
      name: "Sikkim Large Scale Waterbody Map",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/QC_Pass_SK_10K_DerivedWS/wms?LAYERS=SK_WATERBODY_10K&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.041127487078,27.190653486839,88.910036764246,28.031107588401&WIDTH=316&HEIGHT=306",
    },
    {
      name: "Sikkim Large Scale Snow Cover Map",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/QC_Pass_SK_10K_DerivedWS/wms?LAYERS=SK_SNOW_10K&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.041858471836,27.190653486839,88.926796202195,28.031107588401&WIDTH=322&HEIGHT=306",
    },
    {
      name: "Sikkim Large Scale Wetland Map 2005-2007",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/Sikkim_Water_ResourceWS/wms?LAYERS=SK_WETLAND&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.041127486944,27.193400068627,88.910036763686,28.033854170189&WIDTH=316&HEIGHT=306",
    },
    {
      name: "Sikkim Waterbody Map 2011-2012",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/SK_Derived_LULC_11_12WS/wms?LAYERS=SK_WATERBODIES_50K_2011_2012&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.356291260813,88.908, 28.040190186595&WIDTH=1600&HEIGHT=1200",
    },
    {
      name: "Sikkim Waterbody Map 2005-2006",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/SK_Derived_LULC_05_06WS/wms?LAYERS=SK_WATERBODIES_50K_2005_2006&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.356291260813,88.908, 28.040190186595&WIDTH=314&HEIGHT=852",
    },
    {
      name: "Sikkim Waterbody Map 2015-2016",
      imageUrl:
        "https://www.nesdr.gov.in/igistile/SK_Derived_LULC_15_16WS/wms?LAYERS=SK_WATERBODIES_50K_2015_2016&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.044482992674,27.040935734213,88.90690000821,28.449932316245&WIDTH=314&HEIGHT=513",
    },
  ];

  return (
    <div className="min-h-screen px-5 py-3 bg-blue-200">
      <h1 className="text-3xl font-bold text-center text-black mb-8">
        SIKKIM WATER RESOURCES
      </h1>
      <div className="flex flex-wrap gap-10 items-center justify-center">
        {links.map((link, index) => (
          <div key={index}>
            <Link
              to={`/nhp/water-resources/${link.name}`}
              className="flex flex-col justify-center items-center gap-2 bg-white px-5 py-3"
            >
              <img
                src={link.imageUrl}
                className="border-2 border-blue-700 w-56 h-56"
              />
              <span className="font-bold">{link.name}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapCollection;
