import React, { useEffect, useMemo, useState } from "react";
import { formatDate } from "./formatDate";
import {
  ArrowBigLeft,
  ArrowBigRight,
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
// const data = [
//   {
//     id: "1",
//     riverCatchment: "Teesta",
//     riverName: "Rateychu",
//     stationName: "Rateychu (B1) Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 1663.423,
//         time: "15:13",
//       },
//       { date: "13-11-2024", level: 1663.552, time: "14:53" },
//     ],
//   },
//   {
//     id: "2",
//     riverCatchment: "Teesta",
//     riverName: "Roro-chu",
//     stationName: "Lagyep Bridge,Ranipool",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "3",
//     riverCatchment: "Teesta",
//     riverName: "Rongni-chu",
//     stationName: "32 no, Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "4",
//     riverCatchment: "Teesta",
//     riverName: "Teesta",
//     stationName: "Mamring Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "5",
//     riverCatchment: "Teesta",
//     riverName: "Dikchu khola",
//     stationName: "Dikchu Khola",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "6",
//     riverCatchment: "Teesta",
//     riverName: "Rangpo-chu",
//     stationName: "Lossing Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "7",
//     riverCatchment: "Teesta",
//     riverName: "Rongli-chu",
//     stationName: "Rongli Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "8",
//     riverCatchment: "Teesta",
//     riverName: "Rangpo-chu",
//     stationName: "Rorathang Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "9",
//     riverCatchment: "Teesta",
//     riverName: "Rangeet",
//     stationName: "Akkar Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
//   {
//     id: "10",
//     riverCatchment: "Teesta",
//     riverName: "Rangeet",
//     stationName: "Legship Bridge",
//     readings: [
//       {
//         date: "12-11-2024",
//         level: 827.203,
//         time: "20:00:00",
//       },
//       { date: "13-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "11-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "10-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "9-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "8-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "7-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "6-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "5-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "4-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "3-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "2-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "1-11-2024", level: 827.165, time: "08:00:00" },
//       { date: "30-10-2024", level: 827.165, time: "08:00:00" },
//       { date: "29-10-2024", level: 827.165, time: "08:00:00" },
//       { date: "28-10-2024", level: 827.165, time: "08:00:00" },
//       { date: "27-10-2024", level: 827.165, time: "08:00:00" },
//       { date: "26-10-2024", level: 827.165, time: "08:00:00" },
//       { date: "25-10-2024", level: 827.165, time: "08:00:00" },
//       { date: "24-10-2024", level: 827.165, time: "08:00:00" },
//     ],
//   },
// ];

export function WaterLevelTable(trigger) {
  const [startIndex, setStartIndex] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const ref = doc(db, "nhp", "waterLevels");
      const snapshot = await getDoc(ref);
      setData(snapshot.data().data);
    };
    fetchData();
  }, [trigger]);
  const sortedDates = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    const allDates = Array.from(
      new Set(
        data.flatMap((row) => row.readings.map((reading) => reading.date))
      )
    );

    return allDates.sort((a, b) => {
      const [dayA, monthA, yearA] = a.split("-").map(Number);
      const [dayB, monthB, yearB] = b.split("-").map(Number);
      return (
        new Date(yearB, monthB - 1, dayB).getTime() -
        new Date(yearA, monthA - 1, dayA).getTime()
      );
    });
  }, [data]);

  const visibleDates = sortedDates.slice(startIndex, startIndex + 5);

  const handlePrevious = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - 5));
  };

  const handleNext = () => {
    setStartIndex((prevIndex) =>
      Math.min(sortedDates.length - 5, prevIndex + 5)
    );
  };

  if (data.length === 0) {
    return <div className="text-center p-4">No data available</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <button
          onClick={handlePrevious}
          disabled={startIndex === 0}
          variant="outline"
          size="sm"
        >
          <ArrowBigLeft className="h-10 w-10 ml-2" />
        </button>
        <button
          onClick={handleNext}
          disabled={startIndex >= sortedDates.length - 5}
          variant="outline"
          size="sm"
        >
          <ArrowBigRight className="h-10 w-10 ml-2" />
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border sticky left-0 bg-white border-gray-300 p-2 text-sm font-semibold">
                Sl. No.
              </th>
              <th className="border border-gray-300 p-2 text-sm font-semibold">
                River Catchment
              </th>
              <th className="border border-gray-300 p-2 text-sm font-semibold">
                Name of River
              </th>
              <th className="border border-gray-300 p-2 text-sm font-semibold">
                Station Name
              </th>
              {visibleDates.map((date) => (
                <th
                  key={date}
                  className="border border-gray-300 p-2 text-sm font-semibold"
                >
                  {date}
                  <div className="text-xs font-normal">Level (m)</div>
                  <div className="text-xs font-normal">Time (hrs)</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr
                key={row.id}
                className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="border bg-white sticky left-0 border-gray-300 p-2 text-center">
                  {rowIndex + 1}
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  {row.riverCatchment}
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  {row.riverName}
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  {row.stationName}
                </td>
                {visibleDates.map((date) => {
                  const reading = row.readings.find((r) => r.date === date);
                  return (
                    <td
                      key={date}
                      className="border border-gray-300 p-2 text-center"
                    >
                      {reading ? (
                        <>
                          <div>{reading?.level}</div>
                          <div className="text-xs text-gray-600">
                            {reading.time}
                          </div>
                        </>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
