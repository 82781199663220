import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import XYZ from "ol/source/XYZ";
import ImageStatic from "ol/source/ImageStatic";
import { fromLonLat, transformExtent } from "ol/proj";
import {
  defaults as defaultControls,
  FullScreen,
  ZoomToExtent,
  Zoom,
} from "ol/control";
import { useParams } from "react-router-dom";

const SikkimMap = () => {
  const { mapType } = useParams();
  const [blobId, setBlobID] = useState("");
  useEffect(() => {
    const setURL = () => {
      if (mapType == "Sikkim Wetland Map 2017-2018") {
        setBlobID(
          "https://www.nesdr.gov.in/geoportal/cgi-bin/IGiS_Ent_service.exe?IEG_PROJECT=SK_Wetland_WS&LAYERS=SK_Wetland_17_18&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.230360473633,88.908,27.910139526367&WIDTH=314&HEIGHT=247"
        );
      } else if (mapType == "Sikkim Large Scale Drainage Network") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/Sikkim_Water_ResourceWS/wms?LAYERS=SK_Drainage&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.356291260813,88.908, 28.040190186595&WIDTH=163&HEIGHT=382"
        );
      } else if (mapType == "Sikkim Large Scale Waterbody Map") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/QC_Pass_SK_10K_DerivedWS/wms?LAYERS=SK_WATERBODY_10K&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.041127487078,27.190653486839,88.910036764246,28.031107588401&WIDTH=316&HEIGHT=306"
        );
      } else if (mapType == "Sikkim Large Scale Snow Cover Map") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/QC_Pass_SK_10K_DerivedWS/wms?LAYERS=SK_SNOW_10K&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.041858471836,27.190653486839,88.926796202195,28.031107588401&WIDTH=322&HEIGHT=306"
        );
      } else if (mapType == "Sikkim Large Scale Wetland Map 2005-2007") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/Sikkim_Water_ResourceWS/wms?LAYERS=SK_WETLAND&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.041127486944,27.193400068627,88.910036763686,28.033854170189&WIDTH=316&HEIGHT=306"
        );
      } else if (mapType == "Sikkim Waterbody Map 2011-2012") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/SK_Derived_LULC_11_12WS/wms?LAYERS=SK_WATERBODIES_50K_2011_2012&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.356291260813,88.908, 28.040190186595&WIDTH=1600&HEIGHT=1200"
        );
      } else if (mapType == "Sikkim Waterbody Map 2005-2006") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/SK_Derived_LULC_05_06WS/wms?LAYERS=SK_WATERBODIES_50K_2005_2006&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.046,27.356291260813,88.908, 28.040190186595&WIDTH=314&HEIGHT=852"
        );
      } else if (mapType == "Sikkim Waterbody Map 2015-2016") {
        setBlobID(
          "https://www.nesdr.gov.in/igistile/SK_Derived_LULC_15_16WS/wms?LAYERS=SK_WATERBODIES_50K_2015_2016&TRANSPARENT=TRUE&FORMAT=image%2Fpng&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=88.044482992674,27.040935734213,88.90690000821,28.449932316245&WIDTH=314&HEIGHT=513"
        );
      }
    };
    setURL();
  }, [mapType]);

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!mapRef.current) return;

    const sikkimCoordinates = fromLonLat([88.5122, 27.533]); // Longitude, Latitude of Sikkim
    const sikkimExtent = transformExtent(
      [88.0, 27.0, 89.0, 28.0],
      "EPSG:4326",
      "EPSG:3857"
    );

    const layers = [
      new TileLayer({
        source: new XYZ({
          url: "https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        }),
      }),
    ];

    const newMap = new Map({
      target: mapRef.current,
      layers: layers,
      view: new View({
        center: sikkimCoordinates,
        zoom: 8,
        minZoom: 7,
        maxZoom: 19,
      }),
      controls: defaultControls().extend([
        new FullScreen(),
        new ZoomToExtent({
          extent: sikkimExtent,
        }),
        new Zoom(),
      ]),
    });

    setMap(newMap);

    return () => {
      if (newMap) {
        newMap.setTarget(undefined);
      }
    };
  }, []);

  useEffect(() => {
    if (!map) return;

    const sikkimExtent = transformExtent(
      [88.0, 27.0, 89.0, 28.0],
      "EPSG:4326",
      "EPSG:3857"
    );
    const verticalOffset = 5000;
    const diagonalOffset = 4000; // Adjust the offset as needed
    const wmsExtent = [
      sikkimExtent[0] +
        (sikkimExtent[2] - sikkimExtent[0]) * 0.0 +
        diagonalOffset, // Move right
      sikkimExtent[1] +
        (sikkimExtent[3] - sikkimExtent[1]) * 0.1 +
        verticalOffset +
        diagonalOffset, // Move up
      sikkimExtent[0] +
        (sikkimExtent[2] - sikkimExtent[0]) * 0.88 +
        diagonalOffset, // Move right
      sikkimExtent[1] +
        (sikkimExtent[3] - sikkimExtent[1]) * 1 +
        verticalOffset +
        diagonalOffset, // Move up
    ];

    const wmsLayer = new ImageLayer({
      source: new ImageStatic({
        url: blobId,
        imageExtent: wmsExtent,
      }),
      opacity: 1,
    });

    map.addLayer(wmsLayer);

    return () => {
      map.removeLayer(wmsLayer);
    };
  }, [map, blobId]);

  useEffect(() => {
    const handleResize = () => {
      if (map) {
        map.updateSize();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [map]);

  return (
    <div
      ref={mapRef}
      className="w-full h-[80vh] md:h-[90vh] lg:h-[90vh] rounded-lg overflow-hidden"
    />
  );
};

export default SikkimMap;
